jQuery(function ($){

    //Remove the generic preloading class, which can be used in modules or other places
    //to prevent for example CSS animations from running while page is still loading
    $(window).on('load', function() {
        $("body").removeClass("dipi-anim-preload");
    });


    //Script from layout-inject-archives-partial.php
    var $after_nav_archives_layout = jQuery("#dipi-injected-after-nav-archives").detach();
	$after_nav_archives_layout.insertAfter('#main-header');
	$after_nav_archives_layout.insertAfter(".et-l--header");

    //Script from layout-inject-category-partial.php
    var $after_nav_categories_layout = jQuery("#dipi-injected-after-nav-categories").detach();
	$after_nav_categories_layout.insertAfter('#main-header');
	$after_nav_categories_layout.insertAfter(".et-l--header");
});